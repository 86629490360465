<template>
  <div>
    <cta-banner :title="$route.name"/>
    <cta-description class="py-20" v-if="informacion.titulo" :descripcion="informacion" :isImage="true" :hasLink="true" :patrocinar="true" />
  </div>
</template>

<script>
import API from "@/api";
import CtaBanner from '../../components/common/CtaBanner.vue';
import CtaDescription from '../../components/CtaDescription.vue';
import {mapState} from "vuex";

export default {
  name: "Patrocinar",
  components: {
    CtaBanner,
    CtaDescription
  },
  data() {
    return {
      informacion: {}
    }
  },
  created () {
    this.getInfo();
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  methods: {
    async getInfo(){
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.informacion = (items[i].codigo === 'PA02') ? items[i] : this.informacion;
      }
    },
  },
}
</script>

<style>

</style>
